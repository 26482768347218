@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
::-webkit-scrollbar {
  display: none;
}
.react-tel-input {
  width: auto !important;
}
.fade-in {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.fade-in-hidden {
  opacity: 0;
}

.scale-up {
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}

.scale-up-hidden {
  transform: scale(0.95);
}
/* Define transition effects */
.fade-up-hidden {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.fade-up-visible {
  opacity: 1;
  transform: translateY(0);
}

.scale-slide-hidden {
  opacity: 0;
  transform: scale(0.95) translateY(20px);
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.scale-slide-visible {
  opacity: 1;
  transform: scale(1) translateY(0);
}

/* Optional: To smoothen the overflow content */
.flex-container-scroll {
  overflow-x: auto;
}

.flex-container-scroll::-webkit-scrollbar {
  display: none; /* Hide scrollbar for WebKit-based browsers */
}
